<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="consent_inclusive">
  <div id="consent_blackbar"></div>
  <div class="loginContainer FooterAlign" fxFlex="100%" fxLayout fxLayout.xs="column" fxLayout.sm="column">
    <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
    <div class="loginContentBlock" fxLayout="column" fxFlex="100%" fxLayoutAlign="space-between">
      <mat-card class="loginDetailsBlock">
        <mat-card-content>
          <app-logo [logoConfig]="logoConfig"></app-logo>
          <div class="intro-message body-copy" ngClass.sm="med-margin">
            <div *ngIf="logoConfig && logoConfig.logo === 'movePro360'">{{templateString.MOVEPRO360_TXT}}</div>
          </div>
          <div *ngIf="custFedStatus===0">
            <div id="widget" [ngClass]="{ 'inactive-user': inactiveUser || !logoConfig, 'google-idp': showGoogleButton }"></div>
            <div class="message" *ngIf="message && !inactiveUser">{{message}}</div><br />
            <div *ngIf="inactiveUser">
              <div class="heading">{{message}}</div>
              <div class="sub-heading body-copy">
                If you need assistance with your account, please contact your Cartus Representative or the <a
                  href="mailto:helpdesk-cartus@cartus.com">Cartus Help Desk</a>
              </div>
              <div class="login-contain-btn btn-align-center">
                <button mat-flat-button class="mat-button" [routerLink]="['/login']"
                  (click)=" message = ''; inactiveUser = false" type='button'>Back</button>
              </div>
            </div>
          </div>
        </mat-card-content>
        <div *ngIf="custFedStatus===1">
          <app-self-registration-success [custFed]="custFedStatus"></app-self-registration-success>
        </div>
      </mat-card>
      <app-footer *ngIf="custFedStatus===0"></app-footer>
    </div>
  </div>
</div>
