import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';

/** user service to post details of login to the server
 * header
 */
@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    constructor(
        protected baseClient: BaseClientService,
        private readonly logSvc: RemoteLoggingService,
    ) { }

    googleIDPFeatureFlag$: any = new BehaviorSubject<string>(null);
    loginAppFlag$: any = new BehaviorSubject<string>(null);
    anywhereAppFlag$: any = new BehaviorSubject<string>(null);

    async getgoogleIDPFeatureFlag(): Promise<boolean> {
        if (!this.googleIDPFeatureFlag$.value) {
            const response = await firstValueFrom(this.getFeatureFlag('enable-google-idp'));
            this.googleIDPFeatureFlag$.next(response);
        }
        return Promise.resolve(this.googleIDPFeatureFlag$.value && this.googleIDPFeatureFlag$.value.toString() === 'true');
    }

    async getLoginApp(value: string): Promise<boolean> {
        if (!this.loginAppFlag$.value) {
            const response = await firstValueFrom(this.getFeatureFlag(value));
            this.loginAppFlag$.next(response);
        }
        return Promise.resolve(this.loginAppFlag$.value && this.loginAppFlag$.value.toString() === 'true')
    }
    async getAnywhereApp(value: string): Promise<boolean> {
        if (!this.anywhereAppFlag$.value) {
            const response = await firstValueFrom(this.getFeatureFlag(value));
            this.anywhereAppFlag$.next(response);
        }
        return Promise.resolve(this.anywhereAppFlag$.value && this.anywhereAppFlag$.value.toString() === 'true')
    }

    getFeatureFlag(name: string): Observable<any> {
        return this.baseClient.getById<boolean>('/featureFlag/' + name).pipe(
            map(r => r.body),
            catchError((err, source) => {
                const empty: boolean = null;
                this.logSvc.logError(err);
                return of(empty);
            })
        );
    }
}
