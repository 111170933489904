import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { User } from 'src/app/core/models/User';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ManageUserSharedService } from '../../../core/services/manage-user-shared.service';
import { Router } from '@angular/router';
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { SelfRegistrationSetupService } from 'src/app/core/services/self-registration-setup.service';
import { APIResponse } from 'src/app/core/models/APIResponse';

@Component({
  selector: 'app-invalid-registration',
  templateUrl: './invalid-registration.component.html',
  styleUrls: ['./invalid-registration.component.scss']
  //,changeDetection: ChangeDetectionStrategy.Default,
})
export class InvalidRegistrationComponent implements OnInit {
  templateString = {
    BANNER_TXT: `Technology That Moves You`,
    LOGIN_BTN: `MobilifyHR`
  };
  /** Variable to store the product name*/
  productName: string;
  /** Variable to store user Details */
  userDetails: User;
  /** variable to have static content text */
  contentText1: string;
    /** variable to have static content text */
  contentText2: string;
  /** Used to hold logo config value */
  logoConfig: any;
  /** Used to hold current error from self reg setup service  */
  currentError: APIResponse;
  /**Used to hold hidden status for redirect button */
  showLoginButton: boolean = false;

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly moveSharedService: ManageUserSharedService,
    private readonly router: Router,
    private readonly cookieLogoSvc: ManageCookieLogoService,
    private readonly registrationSetupService: SelfRegistrationSetupService) {
  }

  ngOnInit() {
    this.currentError = this.registrationSetupService.currentError;
    this.moveSharedService.loginUserDetails.subscribe(loginUserDetails => {
      this.userDetails = loginUserDetails;
      const val = this.cookieLogoSvc.setLogoFromUser(this.userDetails);
      this.logoConfig = { logo: val, type: 'alt' }
      if (this.userDetails.product === 'Alpha') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('mobilifyHR_ProductName').toString();
      } else if (this.userDetails.product === 'MovePro') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('movePro360_ProductName').toString();
      } else if (this.userDetails.product === 'BenefitsBuilder') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('benefitsBuilder_ProductName').toString();
      } else if (this.userDetails.product === 'CompensationServices') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('compensationServices_ProductName').toString();
      } else if (this.userDetails.product === 'CartusOnline') { // Non-Springboard Product Name is still returned by the API
        this.productName = this.appConfig.getConfig('cartusOnline_ProductName').toString();
      } else if (this.userDetails.product === 'Atlas') {
        this.productName = this.appConfig.getConfig('atlas_ProductName').toString();
      } else {
        this.productName = 'this service';
      }
      if (this.userDetails.product === 'CartusOnline' || this.userDetails.product === 'Atlas') {
        this.templateString.LOGIN_BTN = 'Login';
      } else {
        this.templateString.LOGIN_BTN = this.productName;
      }
    });

    if (!!this.currentError) {
      if (this.currentError.statusCode === 400) this.showLoginButton = true;
      let messageLines: string[] = this.currentError.message.replace('$productName',this.productName).split('\n');
      this.contentText1 = messageLines[0];
      this.contentText2 = messageLines.length > 1 ? messageLines[1] : '';
    }
  }
  redirect() {
    this.router.navigateByUrl('login');
  }

}
