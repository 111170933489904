import { Component, OnInit, OnDestroy, ViewEncapsulation, HostListener, NgZone, HostBinding } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Title } from "@angular/platform-browser";
import { SpringboardApp } from "src/app/public/models/springboardApps.model";
import { SpringboardSetupService } from "src/app/core/services/springboard-setup.service";
import { Subscription, BehaviorSubject, firstValueFrom, forkJoin } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { Sort } from "@angular/material/sort";
import { UserContexts } from "src/app/core/models/UserContexts.model";
import { Context } from "src/app/core/models/Context.model";
import { UserCapabilities } from "src/app/core/models/PartyRoles";
import { AuthenticationService } from "src/app/core/services/authentication.service";
import { ManageCookieLogoService } from 'src/app/core/services/manage-cookie-logo.service';
import { AppConfigService } from "src/app/core/services/app-config.service";
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { Router } from "@angular/router";
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { SpringboardTimeoutService } from 'src/app/core/services/springboard-timeout.service';
import Timeout = NodeJS.Timeout;
import { SpringboardTimeoutComponent } from 'src/app/public/components/springboard/springboard-timeout/springboard-timeout.component';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { FeatureFlagService } from "src/app/core/services/feature-flag.service";
import { RemoteLoggingService } from "src/app/core/services/remote-logging.service";

@Component({
  selector: "app-springboard",
  templateUrl: "./springboard.component.html",
  styleUrls: ["./springboard.component.scss"],
  encapsulation: ViewEncapsulation.None
})

export class SpringboardComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'app-springboard'
  /** subscription for preference */
  prefSub: Subscription;
  /** referred */
  referred: boolean = false;
  /** referrer authorized */
  referrerAuthorized: boolean = false;
  /** referrer app name */
  referrerAppName: string;
  /** show referrer error */
  referrerError: boolean = false;
  /** referrerMessage variable to store the current referrer message */
  referrerMessage: string = "You have not been granted access to $appName.";
  /** referrerMessage subject to emit changes to referrerMessage */
  referrerMessage$ = new BehaviorSubject<string>(this.referrerMessage);
  /** url regex */
  urlRegex: RegExp = /(\/#|\/|#)$/
  /** show context selection */
  displayContextSelection: boolean = false;
  /** show context selection menu option */
  displayContextOption: boolean = true;
  /** show error */
  springboardError: boolean = false;
  /** show logged out */
  displayLoggedOut: boolean = false;
  /** is context selected */
  contextSelected: boolean = false;
  /** select account chosen in user-context component menu */
  contextChange: boolean = false;
  /** context selector dataSource */
  dataSource: MatTableDataSource<Context>;
  /** userContexts to store the contexts available to the user */
  userContexts: UserContexts = { count: 0, contexts: [] };
  /** selectedContext variable to store the selected context */
  selectedContext: Context;
  /** capabilities to store the user capabilities */
  userCapabilities: UserCapabilities[] = [];
  /** accessToken variable to hold access token contained in header */
  accessToken: string;
  /** partyId variable to hold the partyId from the chosen context */
  partyId: string;
  /** partiesRoles to store party and role combinations  */
  partiesRoles: [{ partyId?: string, roles?: string[] }] = [{}];
  /** displayedColumns variable to store the displayed columns array */
  displayedColumns: string[] = ["application", "company", "companyAddr"];
  /** applications variable to store the retrieved applications */
  applications: SpringboardApp[];
  /** show app selection */
  displayAppSelection: boolean = true;
  /** selectedApp variable to store the selected app */
  selectedApp: string = "";
  /** errorHeadings object to store error headings */
  errorHeadings = {
    GENERAL_ERROR: "Please contact the Cartus Help Desk",
    APP_NOT_AVAILABLE: "$appName is currently not available",
    APP_NOT_AVAILABLE_DEFAULT: "The selected application is currently not available",
    NO_APPS_ASSIGNED: "You currently have no applications assigned",
    LOGIN_FAILED: "The logon attempt failed"
  };
  /** errorHeaders variable to store the current error header */
  errorHeader: string = this.errorHeadings.GENERAL_ERROR;
  /** snackbar horizontal position */
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  /** snackbar vertical position */
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  /** sbErrorConfig object to hold snackBar config for errors */
  sbErrorConfig: any = {
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    data: '',
    duration: 10000
  };
  /** sbTimeoutConfig object to hold snackBar config for timeout */
  sbTimeoutConfig: any = {
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
  };
  /** logOutMessages object to store logged out messages */
  logOutMessages = {
    NORMAL: "You have been successfully logged out",
    TIMEOUT: "You have been logged out due to inactivity"
  }
  /** logOutMessage variable to store the current log out message */
  logOutMessage: string = this.logOutMessages.NORMAL;
  /** idle timeout minutes */
  idleTimeoutMinutes = 15;
  /** token cookie name */
  tokenCookieName = 'car-ses-tok';
  /** idle timeout cookie name */
  idleCookieName = 'lastAction';
  /** idle timeout keep-alive interval */
  pingInterval = 0;
  /** idle timeout poll interval */
  pollInterval = 1000;
  /** idle timeout check time */
  idleCheckSeconds = 60;
  /** idle timeout last mouse X */
  lastX = 0;
  /** idle timeout last mouse Y */
  lastY = 0;
  /** nodeJS timeout */
  lastTimeOut: Timeout;
  /** idle timeout warning */
  isIdleTimeoutWarning = false;
  /** initiate timeout warning  */
  idleTimeoutWarningInitiateMinutes:any;
  /** idle timeout warning minutes */
  idleTimeoutWarningMinutes = 13;
  /** session token refresh interval */
  refreshTokenInterval: any;
  /** session token refresh timeout minutes */
  refreshTokenTimeout = 9; // 3 chances to update in a 30 minute window
  /** app-context cookie name  */
  appContextCookieName = 'app-context'
  //** GetLoginApp feture flag status */
  loginAppFlag : boolean=false;
  /** Set application Name */
  applicationName: string
  /** Set BB context Name */
  displayBBContext: boolean = false;
  /** Set COL context Name */
  displayCOLContext: boolean = false;
  /** Used to store appId */
  appId: number;

  /**
   * Base constructor
   * @param  spinner: NgxSpinnerService
   * @param  titleService: Title
   * @param  springboardSetupService: SpringboardSetupService
   * @param  authSvc: AuthenticationService
   * @param  router: Router
   * @param  snackBar: MatSnackBar
   * @param  cookieLogoService: ManageCookieLogoService
   * @param  appConfig: AppConfigService
   * @param  springboardTimeoutService: SpringboardTimeoutService
   * @param  userPreferencesService: UserPreferencesService
   * @param _ngZone: NgZone
   * @param _snackBar: MatSnackBar
   */
  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly titleService: Title,
    private readonly springboardSetupService: SpringboardSetupService,
    private readonly authSvc: AuthenticationService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly cookieLogoService: ManageCookieLogoService,
    private readonly appConfig: AppConfigService,
    private readonly springboardTimeoutService: SpringboardTimeoutService,
    private readonly userPreferencesService: UserPreferencesService,
    private _ngZone: NgZone,
    private _snackBar: MatSnackBar,
    private featureFlag: FeatureFlagService,
    private logger : RemoteLoggingService
  ) {}

  ngOnInit(): void {
    this.appId = (sessionStorage.getItem('appId')) ? parseInt(sessionStorage.getItem('appId').replace(/"/g, "")): 0;
    this.spinner.show();
    this.titleService.setTitle("Springboard");
    this.springboardTimeoutService.timeoutData$.pipe().subscribe(response => {
      if(response) {
        this._ngZone.run(() => {
          this._snackBar.openFromComponent(SpringboardTimeoutComponent, this.sbTimeoutConfig);
        });
      } else {
        if(this.isIdleTimeoutWarning === true){
          this._snackBar.dismiss();
        }
      }
    });
    this.refreshSessionInApp();
    this.startIdleTimeCountDown();
    this.authSvc.trySso().then((tokenOrTokens: any) => {
      if (tokenOrTokens && tokenOrTokens.tokens) {
        console.log("SSO authenticated");
        this.cookieLogoService.setSessionCookie(tokenOrTokens.tokens.accessToken);
        this.accessToken = tokenOrTokens.tokens.accessToken.accessToken;
        this.getUserContexts();
      } else {
        console.log("SSO failed");
        this.accessToken = this.cookieLogoService.getCookie('car-ses-tok')
        if (this.accessToken) {
          console.log("Token retrieved from service")
          this.getUserContexts();
        } else {
          console.log("No okta token stored")
          this.errorHeader = this.errorHeadings.LOGIN_FAILED;
          this.springboardError = true;
          this.spinner.hide();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.prefSub) {
      this.prefSub.unsubscribe();
    }
  }

  /** getUserContexts method to get all party and organization details associated with the partyId contained in the access token */
  async getUserContexts() {
    await this.featureFlag.getLoginApp('enable-login-app')
    .then(res => {this.loginAppFlag=res})
    await firstValueFrom(this.springboardSetupService.getContexts(this.accessToken))
      .then(async partyAndOrganizationDetails => {
        if (!partyAndOrganizationDetails || partyAndOrganizationDetails.length === 0) {
          this.errorHeader = this.errorHeadings.GENERAL_ERROR;
          this.springboardError = true;
          this.spinner.hide();
        } else {
          await this.buildContexts(partyAndOrganizationDetails)
          this.buildPartiesRoles(partyAndOrganizationDetails)
          if (this.userContexts.count === 0) {
            this.errorHeader = this.errorHeadings.GENERAL_ERROR;
            this.springboardError = true;
            this.spinner.hide();
          } else if (this.userContexts.count === 1) {
            this.selectContext(this.userContexts.contexts[0]);
            this.displayContextOption = false;
          } else {
            this.processCapabilities()
          }
        }
      });
  }

   async buildContexts(partyAndOrganizationDetails) {
    for (let item of partyAndOrganizationDetails) {
    // partyAndOrganizationDetails.forEach(async item => {
      if (item.party) {
        let context: Context = {};
        context.partyId = item.party._id;
         await this.getApps(context.partyId, false)
        context.firstName = item.party.currentName && item.party.currentName.names && item.party.currentName.names.length > 0
          ? item.party.currentName.names[0] : null;
        context.lastName = item.party.currentName && item.party.currentName.names && item.party.currentName.names.length > 1
          ? item.party.currentName.names[item.party.currentName.names.length - 1] : null;
        context.multiCurrencyFlag = false; // TODO: not in currently in payload, hardcoding to false as per discussion
        if (item.organizationDetails) {
          if (item.organizationDetails.entityName) {
            context.clientNo = item.organizationDetails.clientNumber;
            context.clientLegalName = item.organizationDetails.entityName;
          }
          if (item.organizationDetails.contactMechanisms && item.organizationDetails.contactMechanisms.length > 0) {
            const address = item.organizationDetails.contactMechanisms.filter(contactMechanism =>
              contactMechanism.contactType === "address" &&
              contactMechanism.usageType === 'business');
            if (address.length > 0) {
              context.clientAddrLine1 = address[0].addressLines[0] ? address[0].addressLines[0] : null;
              context.clientAddrLine2 = address[0].addressLines[1] ? address[0].addressLines[1] : null;
              context.clientCityName = address[0].city ? address[0].city : null;
              context.clientStProvName = address[0].state ? address[0].state : null;
              context.clientCountryName = address[0].country ? address[0].country : null;
              context.clientPostalCode = address[0].postalCode ? address[0].postalCode : null;
            }
          }
        }
        
        context.application = this.applicationName;
        this.userContexts.contexts.push(context)
      }
    }
    this.userContexts.count = this.userContexts.contexts.length
  }

  buildPartiesRoles(partyAndOrganizationDetails) {
    const now = new Date();
    partyAndOrganizationDetails.forEach((item, index) => {
      if (item.party) {
        this.partiesRoles[index] = { partyId: item.party._id, roles: [] };
        if (item.party.roles) {
          item.party.roles.forEach(role => {
            if (!role.name) {
              this.springboardError = true;
              this.errorHeader = this.errorHeadings.GENERAL_ERROR;
              this.spinner.hide();
            }
            if ((!role.fromDate || (role.fromDate && now > new Date(role.fromDate))) && (!role.toDate || (role.toDate && now < new Date(role.toDate)))) {
              this.partiesRoles[index].roles.push(role.name);
            }
          });
        }
     }
    });
  }

  /** processCapabilities method to get the caps for each context and select the context automatically in some scenarios */
  processCapabilities() {
    const reqs = [
      ...this.userContexts.contexts.map(context => this.springboardSetupService.getCapabilities(this.accessToken, context.partyId)),
    ]
    forkJoin(reqs).subscribe({
      next: allResponses => {
        allResponses.forEach(resp => {
          if (resp && resp.capabilities) {
            this.userCapabilities.push(resp)
          }
        })
      },
      error: err => {
        console.log('error in processCapabilities:', err);
        this.errorHeader = this.errorHeadings.GENERAL_ERROR;
        this.springboardError = true;
        this.spinner.hide();
      },
      complete: () => {
        const partiesCount = this.userContexts.contexts.length
        const legacytransfereeCount = this.partiesRoles.filter(party => party.roles.includes('legacy-transferee')).length;
        const coPortalCount = this.userCapabilities.filter(item => item.capabilities.find(cap =>
          cap.name === 'CartusOnline - Portal Access' && cap.permission === 'allow'
        )).length
        const bbAdminPortalCount = this.userCapabilities.filter(item => item.capabilities.find(cap =>
          cap.name === 'Benefits Builder Program Administration Portal' && cap.permission === 'allow'
        )).length
        const bbTransfereePortalCount = this.userCapabilities.filter(item => item.capabilities.find(cap =>
          cap.name === 'Benefits Builder Transferee Portal' && cap.permission === 'allow'
        )).length
        /*
        if any context has the 'Benefits Builder Transferee Portal' capability, auto-select the first context with that capability
        */
        if (bbTransfereePortalCount > 0) {
          const partyId = this.userCapabilities.find(item => item.capabilities.find(cap =>
            cap.name === 'Benefits Builder Transferee Portal'
          )).partyId;
          this.selectContext(this.userContexts.contexts.find(context => context.partyId === partyId));
          this.displayContextOption = false;

        }
        /*
        if all contexts have the 'CartusOnline - Portal Access' capability
        AND no contexts have the 'Benefits Builder Program Administration Portal' capability, auto-select the first context
        */
        else if (coPortalCount === partiesCount && bbAdminPortalCount === 0) {
          const partyId = this.userCapabilities.find(item => item.capabilities.find(cap =>
            cap.name === 'CartusOnline - Portal Access'
          )).partyId;
          this.selectContext(this.userContexts.contexts.find(context => context.partyId === partyId));
          this.displayContextOption = false;

        }
        /*
        if any context has the 'CartusOnline - Portal Access' capability
        AND one context has the 'Benefits Builder Program Administration Portal' capability,
        auto select the context with the 'Benefits Builder Program Administration Portal' capability
        */
        else if (coPortalCount > 0 && bbAdminPortalCount === 1 && legacytransfereeCount > 0) {
          const partyId = this.userCapabilities.find(item => item.capabilities.find(cap =>
            cap.name === 'Benefits Builder Program Administration Portal'
          )).partyId;
          this.selectContext(this.userContexts.contexts.find(context => context.partyId === partyId));
          this.displayContextOption = false;
        
        }
        /* 
        for all other scenarios, display the context selector
        */
        else {
          if (this.appId === 10 && this.loginAppFlag) {
            this.selectContext(this.userContexts.contexts[0])
            this.displayContextOption = false;
          } else {
            this.dataSource = new MatTableDataSource<Context>(this.userContexts.contexts);
            this.displayContextSelection = true;
            this.spinner.hide();
          }
         
        }
      }
    });
  }

  /** sortContexts method to sort the context selector table */
  sortContexts(sort: Sort): void {
    if (!sort.active || sort.direction === "") return;
    const data = this.userContexts.contexts.slice();
    const sortedData = [...data].sort((a, b) => {
      const isAsc: boolean = sort.direction === "asc";
      switch (sort.active) {
        case "application":
          return this.compare(a.application, b.application, isAsc);
        case "clientLegalName":
          return this.compare(a.clientLegalName, b.clientLegalName, isAsc);
        case "clientAddrLine1":
          return this.compare(a.clientAddrLine1, b.clientAddrLine1, isAsc);
        default:
          return 0;
      }
    });
    this.userContexts.contexts = sortedData;
    this.dataSource = new MatTableDataSource<Context>(sortedData);
  }

  /** compare method used to compare values when sorting the context selector table */
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /** selectContext method to set the context chosen in the context selector */
  selectContext(context: Context, selectAppContextFlag: boolean = false) {
    this.cookieLogoService.setCookie(this.appContextCookieName, context.partyId, 1, '/', '.cartus.com', true);
    sessionStorage.setItem("car-ses-con", context.partyId);
    sessionStorage.setItem("UserContext", JSON.stringify(context));
    this.partyId = context.partyId;
    this.selectedContext = context;
    this.displayContextSelection = false;
    this.getApps(this.partyId, true, selectAppContextFlag);
  }

  /** getApps method to get the apps applicable to the selected context */
  async getApps(partyId: string, redirectStatus: boolean = true, selectAppContextFlag: boolean = false,) {
    this.spinner.show();
    await firstValueFrom(this.springboardSetupService
      .getApps(this.accessToken, partyId, 'refresh'))
      .then(async springboardApps => {
        if (!redirectStatus){
          this.applicationName = (springboardApps && springboardApps.Applications && springboardApps.Applications.length > 0) ? springboardApps.Applications[0].displayName:''
          return;
        } 
        springboardApps.Applications = this.removeDuplicateApps(springboardApps.Applications)
        await this.findReferrer(springboardApps);      
        if (springboardApps && springboardApps.Applications && springboardApps.Applications.length > 0) {
          if (this.referred) {
            if (this.referrerAuthorized) {
              this.applications = springboardApps.Applications;
              this.displayAppSelection = false;
              this.appSelectedHandler(this.referrerAppName, selectAppContextFlag);
            } else {
              this.referrerError = true
              this.applications = springboardApps.Applications;
              this.displayAppSelection = true;
            }
          } else {
            if (springboardApps.Applications.length === 1) {
              this.applications = springboardApps.Applications;
              this.displayAppSelection = false;
              this.appSelectedHandler(springboardApps.Applications[0].name, selectAppContextFlag);
            } else {
              this.applications = springboardApps.Applications;
              const benefitsbuilder = springboardApps.Applications.find(app => app.name.includes('benefitsBuilder'));
              const transfereeCount = this.partiesRoles.filter(party => party.roles.includes('transferee')).length;
              if (benefitsbuilder && transfereeCount > 0 && this.loginAppFlag){
                this.displayAppSelection = false;
                this.appSelectedHandler(benefitsbuilder.name, selectAppContextFlag);
               }
              this.displayAppSelection = true;
            }
          }
        } else {
          this.applications = null;
          this.errorHeader = this.errorHeadings.NO_APPS_ASSIGNED;
          this.springboardError = true;
        }
      });
      this.spinner.hide();
  }

  //** handle the value emitted from child user-context component when 'select account' is chosen */
  contextChangeHandler(valueEmitted: boolean): void {
    if (valueEmitted) {
      this.applications = null;
      this.cookieLogoService.removeCookies([this.appContextCookieName]);
      sessionStorage.removeItem("car-ses-con");
      sessionStorage.removeItem("UserContext");
      this.selectedContext = null;
      this.displayContextSelection = true;
      this.contextSelected = false;
      this.springboardError = false;
      this.referrerError = false;
    }
  }

  handleAppSelectedHandler(appName: any): void{
    this.spinner.hide();
    this.sbErrorConfig.data = appName.length ?
    this.errorHeadings.APP_NOT_AVAILABLE.replace('$appName', appName[0].displayName) :
    this.errorHeadings.APP_NOT_AVAILABLE_DEFAULT;
    this.snackBar.openFromComponent(CustomSnackbarComponent, this.sbErrorConfig);
    // is cartusOnline the only app available to the user?
    if (this.applications.length === 1) {
      // display the app selector because the token call failed
      this.displayAppSelection = true;
    }
  }

  //** handle the value emitted from child springboard-app when an app is selected and redirect if valid */
  async appSelectedHandler(valueEmitted: string, selectAppContextFlag: boolean = false): Promise<void> {
    this.spinner.show();
    const appName = this.applications.filter(app => app.name === valueEmitted)
    let coError: boolean = false;
    if (valueEmitted === "cartusOnline" || (this.appId === 10 && this.loginAppFlag)) {
      await firstValueFrom(this.springboardSetupService.getColToken(this.accessToken, this.partyId, selectAppContextFlag))
      .then(async resp => {
        if(!resp || (resp && resp.status !== 200)) {
          coError = true;
          this.handleAppSelectedHandler(appName)
        }
      })
    } else if ((valueEmitted === "atlas" || valueEmitted ==="atlasReporting") && this.loginAppFlag) {
      await firstValueFrom(this.springboardSetupService.getColToken(this.accessToken, this.partyId))
      .then(async resp => {
        if(!resp || (resp && resp.status !== 200)) {
          coError = true;
          this.handleAppSelectedHandler(appName)
        }
      })
    } else if(!this.loginAppFlag){
      this.logger.logError('Login App Feature Flag is turned off!!!')
    }
    if (!coError) {
      const url = this.springboardSetupService.getAppUrlFromName(valueEmitted);
      if (url && url.indexOf("http") > -1) {
        this.titleService.setTitle("Loading...");
        this.router.navigate(["/externalRedirect", { externalUrl: url }], {
          skipLocationChange: true,
        });
      } else {
        this.sbErrorConfig.data = appName.length ?
        this.errorHeadings.APP_NOT_AVAILABLE.replace('$appName', appName[0].displayName) :
        this.errorHeadings.APP_NOT_AVAILABLE_DEFAULT;
        this.snackBar.openFromComponent(CustomSnackbarComponent, this.sbErrorConfig);
      }
      this.spinner.hide();
    }
  }

  //** handle the value emitted from child user-context when logout is selected */
  logoutHandler(valueEmitted: boolean): void {
    if (valueEmitted) {
      this.authSvc.signOut();
      this.onKeyPress = function(): void {};
      this.onMouseMove = function(): void {};
      clearInterval(this.refreshTokenInterval);
      if (this.lastTimeOut) clearTimeout(this.lastTimeOut);
      if(this.isIdleTimeoutWarning) this._snackBar.dismiss();
      sessionStorage.clear();
      this.cookieLogoService.removeCookies([this.tokenCookieName, this.idleCookieName]);
      this.displayContextSelection = false;
      this.applications = null;
      this.userContexts = null;
      this.selectedContext = null;
      this.springboardError = false;
      this.referrerError = false;
      this.displayLoggedOut = true;
    }
  }

  filterApps(apps: any[]) {
    return apps.filter(app => app.name != 'atlas' && app.name != 'atlasReporting' )
  }

  /** start refresh session interval */
  refreshSessionInApp() {
    this.refreshTokenInterval = setInterval(() => {
      this.refreshToken()
    }, 60000 * this.refreshTokenTimeout);
  }

  /** refresh token */
  refreshToken() {
    if (!this.springboardError) {
      this.authSvc.refreshSession().then((freshToken: any) => {
        if (freshToken) this.cookieLogoService.setSessionCookie(freshToken);
      }).catch(err => {
        console.log('error in freshToken :', err);
      });
    }
  }

  /** start idle timeout */
  startIdleTimeCountDown() {
    if (this.lastTimeOut) clearTimeout(this.lastTimeOut);
    this.refreshIdleCookie();
    this._ngZone.runOutsideAngular(() => {
      this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
    });
  }

  /** refresh idle cookie */
  refreshIdleCookie() {
    this.springboardTimeoutService.timeoutData.next(false);
    const currentTime = new Date().getTime();
    const idleExpireMs = currentTime + (this.idleTimeoutMinutes * 60000) - 500;
    this.idleTimeoutWarningInitiateMinutes = currentTime + (this.idleTimeoutWarningMinutes * 60000) - 500;
    this.isIdleTimeoutWarning = false;
    this.cookieLogoService.setCookie(this.idleCookieName, idleExpireMs.toString(), 1, '/', '.cartus.com', true);
  }

  /** check idle timeout status */
  checkIdle() {
    const idleExpireMs = parseInt(this.cookieLogoService.getCookie(this.idleCookieName), 10);
    const currTimeMs = new Date().getTime();
    if (!this.springboardError && currTimeMs > idleExpireMs) {
      this.logOutMessage = this.logOutMessages.TIMEOUT;
      this.logoutHandler(true);
    } else {
      if(!this.springboardError && !this.isIdleTimeoutWarning && currTimeMs >= this.idleTimeoutWarningInitiateMinutes) {
        this.isIdleTimeoutWarning = true;
        this.springboardTimeoutService.timeoutData.next(true);
      }
      this.pingInterval += this.pollInterval;
      if (this.pingInterval === 1000 * this.idleCheckSeconds) this.pingInterval = 0;
      this._ngZone.runOutsideAngular(() => {
        this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
      });
    }
  }

  /** listen for mouse events */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    if (e.pageX !== this.lastX || e.pageY !== this.lastY) {
      this.lastX = e.pageX;
      this.lastY = e.pageY;
      this.refreshIdleCookie();
    }
  }

  /** listen for keypress events */
  @HostListener('document:keypress', ['$event'])
  onKeyPress() {
    this.refreshIdleCookie();
  }

  /** establish if an application referred to login */
  async findReferrer(springboardApps: any): Promise<void> {
    const applicationList = [
      {
        name: 'movepro360',
        friendlyName: 'MovePro360'
      },
      {
        name: 'mobilifyhr',
        friendlyName: 'Mobilify HR',
      },
      {
        name: 'supplierportal',
        friendlyName: 'Supplier Portal'
      },
      {
        name: 'benefitsbuilder',
        friendlyName: 'Benefits Builder'
      },
      {
        name: 'compensation',
        friendlyName: 'Compensation Services'
      },
      {
        name: 'atlas',
        friendlyName: 'Atlas'
      },
      {
        name: 'cartusOnline',
        friendlyName: 'Cartus Online'
      },
      {
        name: 'atlasReporting',
        friendlyName: 'Atlas Reporting'
      },
      {
        name: 'mobileApp',
        friendlyName: 'Mobile App'
      }
    ];
    let referredURL: string;
    this.prefSub = this.userPreferencesService.getPreference('referrer', false).subscribe(referrer => {
      referredURL = referrer ? referrer : undefined;
    });
    if (referredURL) {
      this.referred = true;
      if (springboardApps && springboardApps.Applications) {
        for (const app of springboardApps.Applications) {
          if (!this.referrerAppName && app.name) {
            const URL = this.springboardSetupService.getAppUrlFromName(app.name);
            if (URL.replace(this.urlRegex, '').toLowerCase() === referredURL.replace(this.urlRegex, '').toLowerCase()) {
              this.referrerAppName = app.name;
              this.referrerAuthorized = true;
            }
          }
        }

        if (this.appId === 10 && this.loginAppFlag) {
          this.referrerAppName = "mobileApp";
          this.referrerAuthorized = true;
        }
        
        if (!this.referrerAuthorized) {
          const strippedUrl = referredURL.replace(/^https?:\/\//, '').replace(/\..*$/, '').toLowerCase();
          const determinedApp = applicationList.filter(loc => strippedUrl.includes(loc.name.toLowerCase()));
          const friendlyAppName = determinedApp.length > 0 ? determinedApp[0].friendlyName : referredURL;
          this.referrerMessage$.next(this.referrerMessage.replace('$appName', friendlyAppName));
        }
      }
    }
  }
  
  removeDuplicateApps(apps: SpringboardApp[]) {
    return apps && apps.filter((app, index, self) => index === self.findIndex(app2 => (app2.name === app.name)))
  }

}
